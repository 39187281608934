import { ErrorPage } from "$src/components/error/error";
import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { NotFound } from "$src/views/not-found/not-found";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";

import { App } from "./app";
import { AppLayout } from "./app/layout";
import { conversionRoutes } from "./app/routes/conversion/routes";
import { funnelRoutes } from "./app/routes/funnel/routes";
import { settingsRoutes } from "./app/routes/settings/routes";
import { statementsRoutes } from "./app/routes/statements/routes";
import { AuthLayout } from "./auth/layout";
import { surveyRoutes } from "./survey/routes";

const RouterError = () => {
  const error = useRouteError();

  return <ErrorPage error={error} />;
};

const AuthenticatedRoute = () => {
  const { ready, session } = useAmplifyAuth();
  if (!ready) {
    return null;
  }
  return session ? <Outlet /> : <Navigate to="/login" replace />;
};

const UnauthenticatedRoute = () => {
  const { ready, session } = useAmplifyAuth();
  if (!ready) {
    return null;
  }
  return !session ? <Outlet /> : <Navigate to="/funnel" replace />;
};

const routes = createBrowserRouter([
  {
    Component: App,
    errorElement: <RouterError />,
    children: [
      // Private Routes
      {
        Component: AuthenticatedRoute,
        children: [
          {
            Component: AppLayout,
            errorElement: <RouterError />,
            children: [
              {
                path: "/story",
                async lazy() {
                  const { StoryRoute: Component } = await import(
                    "./app/routes/story/story"
                  );
                  return { Component };
                },
              },
              {
                path: "/",
                Component: () => <Navigate to="/funnel" />,
              },
              {
                path: "funnel",
                children: funnelRoutes,
              },
              {
                path: "conversion",
                children: conversionRoutes,
              },
              {
                path: "statements",
                children: statementsRoutes,
              },
              {
                path: "profile",
                async lazy() {
                  const { ProfileRoute: Component } = await import(
                    "./app/routes/profile/profile"
                  );
                  return { Component };
                },
              },
              {
                path: "imagery",
                async lazy() {
                  const { ImageryRoute: Component } = await import(
                    "./app/routes/imagery/imagery"
                  );
                  return { Component };
                },
              },
              {
                path: "settings",
                children: settingsRoutes,
              },
              {
                path: "milestones",
                async lazy() {
                  const { MilestonesRoute: Component } = await import(
                    "./app/routes/milestones/milestones"
                  );
                  return { Component };
                },
              },
              {
                path: "surveydetails",
                async lazy() {
                  const { SurveyDetailsRoute: Component } = await import(
                    "./app/routes/surveydetails/surveydetails"
                  );
                  return { Component };
                },
              },
              {
                path: "playground",
                async lazy() {
                  const { PlaygroundRoute: Component } = await import(
                    "./app/routes/playground/playground"
                  );
                  return { Component };
                },
              },
              {
                path: "*",
                Component: NotFound,
              },
            ],
          },
        ],
      },

      // Unauthenticated Routes
      {
        Component: UnauthenticatedRoute,
        children: [
          {
            Component: AuthLayout,
            children: [
              {
                path: "login",
                async lazy() {
                  const { LoginRoute: Component } = await import(
                    "./auth/routes/login"
                  );
                  return { Component };
                },
              },
              {
                path: "activate",
                async lazy() {
                  const { ActivateRoute: Component } = await import(
                    "./auth/routes/activate"
                  );
                  return { Component };
                },
              },
              {
                path: "forgot-password",
                async lazy() {
                  const { ForgotPasswordRoute: Component } = await import(
                    "./auth/routes/forgot-password"
                  );
                  return { Component };
                },
              },
            ],
          },
        ],
      },

      // Survey customer view routes
      {
        path: "survey",
        children: surveyRoutes,
      },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={routes} />;
};
