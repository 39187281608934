import { IconButton } from "$src/components/icon-button/icon-button";
import { Slideout } from "$src/components/slideout/slideout";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/use-analytics";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/use-account";
import { useFilters } from "$src/stores/use-filters";
import { useHiddenItems } from "$src/stores/use-hidden-item";
import { media } from "$src/styles";
import { User2 } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import type { BFFOutput } from "@tracksuit/bff/trpc";

import { AccountSelect } from "../account-select/account-select";
import { Button } from "../button/button";
import { ControlledDropdown, DropdownItem } from "../dropdown/dropdown";
import styles from "./brand-dropdown.module.css";

type AccountBrand = NonNullable<BFFOutput["metadata"]["list"]>[0];

/**
 * @component
 * Dropdown to change brand, category and geography.
 */
export const BrandDropdown = () => {
  const [brandOpen, setBrandOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [geographyOpen, setGeographyOpen] = useState(false);
  const isTablet = useMediaQuery(media.tablet);
  const trigger = useRef(null);
  const El = isTablet ? ControlledDropdown : Slideout;
  const [account, accounts, activateAccount] = useAccount((s) => [
    s.active,
    s.accounts,
    s.activate,
  ]);
  const resetFilters = useFilters((s) => s.reset);
  const resetHiddenItems = useHiddenItems((s) => s.reset);
  const [brand, setBrand] = useState<Partial<AccountBrand>>();
  const [category, setCategory] = useState<AccountBrand["category"]>();
  const [geography, setGeography] = useState<AccountBrand["geography"]>();
  const analytics = useAnalytics();
  const accountBrands = useMemo<AccountBrand[]>(
    () =>
      brand && accounts
        ? accounts.filter((acc) => {
            return acc.brandId === brand.brandId;
          })
        : [],
    [brand, accounts],
  );
  const accountBrandCategories = useMemo<AccountBrand[]>(
    () => [
      ...new Map(accountBrands.map((acc) => [acc.category.name, acc])).values(),
    ],
    [accountBrands],
  );
  const accountBrandCategoryGeographies = useMemo<AccountBrand[]>(
    () =>
      accountBrands.filter(
        (accBrandCat) => accBrandCat.category.name === category?.name,
      ),
    [accountBrands, category],
  );
  const isButtonDisabled = !brand || !category || !geography;

  const setAccount = () => {
    const accountBrand = accounts?.find(
      (acc) =>
        acc.brandId === brand?.brandId &&
        acc.category.name === category?.name &&
        acc.geography.id === geography?.id,
    );

    if (accountBrand) {
      resetFilters();
      resetHiddenItems();
      activateAccount(accountBrand.accountBrandId);
      analytics?.track(AnalyticsEvents.ChangeAccount, {
        brand: brand?.brandName,
        category: category?.name,
        geography: geography?.name,
      });
    }
    setOpen(false);
  };

  const formatBrandGeography = (
    brandGeography: string | undefined,
  ): string | undefined => {
    if (!brandGeography) {
      return;
    }

    if (brandGeography === "Auckland") {
      return "AKL";
    }

    const words = brandGeography.split(" ");

    if (words.length === 1) {
      const firstTwoLetters = words[0]?.slice(0, 2).toUpperCase();
      return firstTwoLetters;
    }
    const acronym = words.map((word) => word[0]?.toUpperCase()).join("");
    return acronym;
  };

  useEffect(() => {
    if (account ?? accounts?.length) {
      setBrand((b) => (b ? b : account ?? accounts?.[0]));
    }
  }, [brand, account, accounts]);

  useEffect(() => {
    if (accountBrandCategories.length) {
      setCategory(accountBrandCategories[0]?.category);
    }
  }, [accountBrandCategories]);

  useEffect(() => {
    if (accountBrandCategoryGeographies.length) {
      setGeography(accountBrandCategoryGeographies[0]?.geography);
    }
  }, [accountBrandCategoryGeographies]);

  return (
    <>
      {!isTablet && (
        <IconButton
          large
          tip={!open ? "Brand" : ""}
          icon={User2}
          ref={trigger}
          onClick={() => setOpen((previousState) => !previousState)}
        />
      )}
      <El
        open={open}
        align="left"
        {...(!isTablet ? { triggerRef: trigger } : {})}
        onChange={setOpen as any}
        position="top"
        className={cx(styles.container)}
        label={
          <>
            <div className={styles.divider} />
            <div className={cx(styles.brand, open && styles.open)}>
              <p className={cx(styles["brand-name"], styles.label)}>
                {account?.brandName}
              </p>
              <p className={styles.catgeo}>
                <span className={styles.label}>
                  {account?.category.name && account?.category.name}
                  {String(account?.category.name).length <= 20 ? "," : ""}
                </span>
                <span>{formatBrandGeography(account?.geography.name)}</span>
              </p>
            </div>
          </>
        }
        fixedWidth={false}
        chevronVisible={false}
        data-testid="brand-dropdown-menu"
      >
        <div className={styles.dropdown}>
          <AccountSelect
            selectBy="brand"
            open={brandOpen}
            openChange={setBrandOpen as any}
            label={"Brand"}
            className={styles["brand-options"]}
            selected={brand ? [brand] : []}
            fixedWidth
            onChange={(b) => {
              setBrand(b[0]);
            }}
            disabled={accounts && accounts.length <= 1}
          />
          <ControlledDropdown
            className={cx(styles["brand-options"], styles.category)}
            theme="select"
            fixedWidth
            open={categoryOpen}
            onChange={setCategoryOpen as any}
            label="Category"
            selected={category?.name}
            disabled={accountBrandCategories.length <= 1}
            data-testid="brand-dropdown-category-select"
          >
            {accountBrandCategories.map((acc) => (
              <DropdownItem
                id="category"
                onClick={() => {
                  setCategory(acc.category);
                  setCategoryOpen(false);
                }}
                key={acc.accountBrandId}
              >
                <span className={styles["dropdown-item"]}>
                  {acc.category.name}
                </span>
              </DropdownItem>
            ))}
          </ControlledDropdown>

          <ControlledDropdown
            fixedWidth
            className={cx(styles["brand-options"], styles.geography)}
            theme="select"
            open={geographyOpen}
            onChange={setGeographyOpen as any}
            label="Geography"
            selected={geography?.name}
            disabled={accountBrandCategoryGeographies.length <= 1}
            data-testid="brand-dropdown-geography-select"
          >
            {accountBrandCategoryGeographies.map((acc) => (
              <DropdownItem
                id="geography"
                onClick={() => {
                  setGeography(acc.geography);
                  setGeographyOpen(false);
                }}
                key={acc.accountBrandId}
              >
                <span className={styles["dropdown-item"]}>
                  {acc.geography.name}
                </span>
              </DropdownItem>
            ))}
          </ControlledDropdown>
          <div className={styles.button}>
            <Button
              onClick={setAccount}
              label={"Change account"}
              disabled={isButtonDisabled}
            />
          </div>
        </div>
      </El>
    </>
  );
};
