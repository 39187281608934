import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { type ComponentProps, type ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "usehooks-ts";

import styles from "./slideout.module.css";

export type SlideoutProps = {
  /** Whether slideout is open */
  open: boolean;
  /** Called when slideout closes */
  onChange(open: boolean): void;
  /** Position of the slideout */
  position: "right" | "top";
  /** Whether slideout should close on outside click */
  closeOnOutsideClick?: boolean;
  /** Optional ref of the opening trigger, to exclude from clickOutside */
  triggerRef?: React.RefObject<HTMLButtonElement>;
  /** Optional sticky header */
  header?: ReactNode;
} & ComponentProps<"div">;

/**
 * @component
 * Slideout modal
 */
export const Slideout = ({
  open,
  onChange,
  position = "top",
  closeOnOutsideClick,
  triggerRef,
  children,
  header,
  className,
  ...props
}: SlideoutProps) => {
  const slideoutEl = useRef(null);

  useEffect(() => {
    if (position === "right") {
      return;
    }

    document.documentElement.style.overflow = open ? "hidden" : "";
  }, [open, position]);

  useOnClickOutside(slideoutEl, (e) => {
    if (!closeOnOutsideClick) {
      return;
    }

    if (!e.composedPath().includes(triggerRef?.current as any)) {
      onChange(false);
    }
  });

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={position === "right" ? { x: "100%" } : { y: "-100%" }}
          animate={{ y: "0", x: "0" }}
          exit={position === "right" ? { x: "100%" } : { y: "-100%" }}
          className={cx(
            styles.slideout,
            styles[position],
            position === "right" && mixins.scrollable,
            !!header && styles["with-header"],
            className,
          )}
          style={{
            height: `${document.body.offsetHeight}px`,
          }}
          ref={slideoutEl}
          data-testid={"slideout"}
          transition={{ stiffness: 200, damping: 20 }}
          {...(props as any)}
        >
          {header && <div className={styles.header}>{header}</div>}
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body,
  );
};
