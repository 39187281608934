/* v8 ignore start */
import { raygun } from "$src/lib/raygun";
import { useMetadataList } from "$src/queries/use-metadata";
import { useAccount } from "$src/stores/use-account";
import { useAdminSettings } from "$src/stores/use-admin-settings";
import { useConfig } from "$src/stores/use-config";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAmplifyAuth } from "../use-amplify-auth";
import { useAnalytics } from "../use-analytics";
import { useIsTrailblazer } from "../useIsTrailblazer/use-is-trailblazer";

export const useInitUser = () => {
  const analytics = useAnalytics();
  const config = useConfig((s) => s.config);
  const [ready, setReady] = useState(false);
  const [
    activeAccountBrand,
    accountBrands,
    populateAccountBrands,
    setAccountBrand,
  ] = useAccount((s) => [s.active, s.accounts, s.populate, s.activate]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAmplifyAuth();
  const trailblazer = useIsTrailblazer();
  const [impersonateAnonymous, setAccountSettings] = useAdminSettings((s) => [
    s.settings.impersonateAnonymous,
    s.set,
  ]);
  const anonymous = useMemo(
    () => impersonateAnonymous || config?.ENVIRONMENT === "test",
    [impersonateAnonymous],
  );
  const ldClient = useLDClient();
  const queryAccBrand = new URLSearchParams(useLocation().search).get(
    "accountBrandId",
  );
  const { data: accounts } = useMetadataList(
    user?.attributes["custom:isadmin"] === "Y"
      ? null
      : user?.attributes["custom:clients"]
          ?.split(",")
          .filter((id: string) => id.length > 0),
  );

  // Populate user's available accountBrands
  useEffect(() => {
    if (!accounts?.length) {
      return;
    }
    populateAccountBrands(accounts);
  }, [accounts, populateAccountBrands]);

  useEffect(() => {
    if (!accountBrands?.length || activeAccountBrand) {
      return;
    }

    setAccountBrand(
      queryAccBrand ? Number(queryAccBrand) : accountBrands[0]!.accountBrandId,
    );
  }, [accountBrands, activeAccountBrand, queryAccBrand, setAccountBrand]);

  useEffect(() => {
    setAccountSettings({
      includeIncompleteMonths:
        !!activeAccountBrand?.featuresConfig.includeIncompleteMonths,
    });
  }, [activeAccountBrand]);

  useEffect(() => {
    const email = user?.attributes.email?.toLowerCase();
    const identify = async () => {
      await ldClient?.identify({
        kind: "user",
        key: user?.username,
        email: anonymous ? null : email,
        trailblazer: anonymous ? null : trailblazer,
        accountBrandId: activeAccountBrand?.accountBrandId,
        accountId: String(activeAccountBrand?.clientId),
        globalAdmin: anonymous ? "N" : user?.attributes["custom:isadmin"],
        clientAdmin: user?.attributes["custom:client_admin"],
        environment: config?.ENVIRONMENT,
      });

      window.StonlyWidget("identify", user?.username, {
        trailblazer,
      });

      analytics?.identify(user?.username, {
        email,
        trailblazer,
      });

      raygun?.("setUser", {
        identifier: email ?? "",
        email,
        isAnonymous: false,
        uuid: user?.username,
      });
    };
    if (
      activeAccountBrand !== undefined &&
      ldClient &&
      user !== null &&
      user !== undefined &&
      trailblazer !== undefined
    ) {
      identify().then(() => setReady(true));
    }
  }, [ldClient, user, trailblazer, activeAccountBrand, anonymous]);

  return ready;
};
/* v8 ignore end */
