import { z } from "zod";

import { funnelQuestionType } from "./questionType";
import { SurveyAddOn } from "./surveyAddOn";

export const SurveyConfig = z.object({
  id: z.string().uuid(),
  brandName: z.string(),
  geography: z.object({
    id: z.number(),
    name: z.string(),
  }),
  idealLaunchDate: z.string().nullish(),
  category: z.object({
    proposedCategory: z.string().nullish(),
    proposedCategoryGeography: z
      .object({
        category: z.object({
          name: z.string(),
        }),
      })
      .nullish(),
    finalCategoryGeography: z
      .object({
        category: z.object({
          name: z.string(),
        }),
      })
      .nullish(),
    qualifyingQuestion: z.string().nullish(),
    qualifyingOptions: z.string().array().nullish(),
  }),
  researchApproval: z
    .object({
      categoryGeographyId: z.number(),
      categoryName: z.string(),
      qualifyingQuestion: z.string().nullish(),
      qualifyingOptions: z.string().array().nullish(),
    })
    .nullish(),
  status: z.enum([
    "DRAFT",
    "PENDING_CATEGORY_APPROVAL",
    "PENDING_CUSTOMER_PRE_APPROVAL",
    "CUSTOMER_PRE_APPROVED",
    "PENDING_RESEARCH_APPROVAL",
    "RESEARCH_APPROVED",
    "PENDING_CUSTOMER_APPROVAL",
    "APPROVED",
    "PRELIVE",
    "LIVE",
  ]),
  competitors: z
    .object({
      id: z.string().uuid(),
      brandName: z.string(),
      websiteUrl: z.string().nullish(),
      logoUrl: z.string().nullish(),
    })
    .array(),
  statements: z
    .object({ id: z.string().uuid(), statement: z.string() })
    .array(),
  customQuestions: z
    .object({
      id: z.number(),
      content: z.string(),
      options: z
        .object({
          id: z.number(),
          optionText: z.string(),
          qualifyingOption: z.boolean(),
        })
        .array(),
    })
    .array(),
  agencies: z
    .object({
      id: z.number(),
      name: z.string(),
      email: z.string(),
      type: z.enum([
        "BRAND",
        "CREATIVE",
        "DESIGN",
        "DIGITAL",
        "FULL_SERVICE",
        "MEDIA",
        "PR",
        "OTHER",
      ]),
      otherType: z.string().nullish(),
      referringPartner: z.boolean(),
    })
    .array(),
  // billing: z.object({
  //   businessName: z.string(),
  //   name: z.string(),
  //   email: z.string().email(),
  //   currency: z.string(),
  //   invoiceStartDate: z.string().datetime({ offset: true }),
  //   purchaseOrder: z.string(),
  //   notes: z.string(),
  // }),
  supportEmail: z.string(),
  addOns: SurveyAddOn.array(),
  funnelQuestions: z.preprocess(
    (v) => (Array.isArray(v) ? v : []),
    z
      .object({
        questionText: z.string(),
        questionType: funnelQuestionType.optional(),
        options: z.object({ optionText: z.string() }).array().optional(),
      })
      .array(),
  ),
});

export type SurveyConfig = z.infer<typeof SurveyConfig>;

export const GetSurveyConfigsResponse = z.object({
  trackers: SurveyConfig.array(),
  status: z.enum([
    "DRAFT",
    "CUSTOMER_LINK_GENERATED",
    "ACCEPTED",
    "REJECTED",
    "REQUESTED_CHANGES",
    "EXPIRED",
  ]),
});

export type GetSurveyConfigsResponse = z.infer<typeof GetSurveyConfigsResponse>;
