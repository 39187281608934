import Logo from "$src/assets/img/logo.svg?react";
import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { useNav } from "$src/hooks/use-nav";
import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/use-account";
import { media } from "$src/styles";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import { BrandDropdown } from "../brand-dropdown/brand-dropdown";
import { SettingsDropdown } from "../settings-dropdown/settings-dropdown";
import styles from "./header.module.css";
import { MobileMenu } from "./lib/mobile-menu/mobile-menu";

/**
 * @component
 * new website header
 */
export const Header = () => {
  const isDesktop = useMediaQuery(media.desktop);
  const { pathname } = useLocation();
  const account = useAccount((s) => s.active);
  const nav = useNav();
  const { session } = useAmplifyAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    mobileOpen && isDesktop && setMobileOpen(false);
  }, [isDesktop, mobileOpen]);

  return (
    <header className={cx(styles.header)}>
      {isDesktop ? (
        <>
          <Link className={styles.logo} to="/">
            <Logo className={cx(styles["logo-inner"])} data-testid="logo" />
          </Link>
          <nav className={styles.nav}>
            {nav.map(({ label, path, beta }) => (
              <Link
                className={cx(
                  styles.link,
                  /* v8 ignore next: can't test pathname */
                  pathname.startsWith(path) && styles.active,
                )}
                to={path}
                key={path}
              >
                {label}
                {beta && <span className={styles["link-badge"]}>BETA</span>}
              </Link>
            ))}
          </nav>
          {!!session && (
            <div className={styles.actions}>
              <SettingsDropdown />
              {account && <BrandDropdown />}
            </div>
          )}
        </>
      ) : (
        <>
          <Link className={styles.logo} data-testid="logo-mobile" to="/">
            <Logo className={styles["logo-inner"]} />
          </Link>
          {session && (
            <div className={styles.actions}>
              <SettingsDropdown />
              <BrandDropdown />
              <MobileMenu />
            </div>
          )}
        </>
      )}
    </header>
  );
};
