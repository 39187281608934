/* v8 ignore start */
import {
  FloatingArrow,
  type Placement,
  arrow,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { type MutableRefObject, forwardRef, useState } from "react";

export type UsePopoutProps = {
  placement: Placement;
  arrowRef: Element | MutableRefObject<Element | null> | null;
  interactions?: {
    click?: boolean;
    hover?: boolean;
  };
};

export const PopoutArrow = forwardRef(function PopoutArrow(
  { context }: any,
  ref: any,
) {
  return (
    <FloatingArrow
      fill="var(--color-bg)"
      filter="drop-shadow(var(--shadow-lg))"
      tipRadius={1}
      height={9}
      width={13}
      ref={ref}
      context={context}
    />
  );
});

export const usePopout = ({
  placement,
  arrowRef,
  interactions = { click: true, hover: true },
}: UsePopoutProps) => {
  const [open, setOpen] = useState(false);
  const { x, y, strategy, refs, context } = useFloating({
    placement,
    middleware: [offset(12), flip(), shift(), arrow({ element: arrowRef })],
    open,
    onOpenChange: setOpen,
  });
  const click = useClick(context, {
    enabled: interactions.click,
    toggle: false,
  });
  const hover = useHover(context, { enabled: interactions.hover });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    hover,
    dismiss,
  ]);
  const { isMounted, styles: floatingStyles } = useTransitionStyles(context, {
    initial: ({ side }) => ({
      opacity: 0,
      transform: `translateY(${side === "top" ? "3px" : "-3px"})`,
    }),
    close: ({ side }) => ({
      opacity: 0,
      transform: `translateY(${side === "top" ? "3px" : "-3px"})`,
    }),
  });

  return {
    open,
    floatingStyles: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
      zIndex: "var(--layer-5)",
      ...floatingStyles,
    },
    isMounted,
    getReferenceProps,
    getFloatingProps,
    refs,
    context,
  };
};
/* v8 ignore end */
