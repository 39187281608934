import { AnalyticsEvents, useAnalytics } from "$src/hooks/use-analytics";
import { cx, isExternalURL } from "$src/lib/utils";
import { motion } from "framer-motion";
import { Lock } from "lucide-react";
import { type ComponentProps, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { useHover } from "usehooks-ts";

import styles from "./dropdown-item.module.css";

export type DropdownItemProps = {
  id: string;
  disabled?: boolean;
  active?: boolean;
  href?: string;
  noHighlight?: boolean;
  locked?: boolean;
  "data-testid"?: string;
} & ComponentProps<"div">;

/**
 * @component
 * Highlighted dropdown items
 */
export const DropdownItem = ({
  id,
  disabled,
  locked,
  active,
  href,
  onClick,
  className,
  noHighlight,
  children,
  ...props
}: DropdownItemProps) => {
  const item = useRef<HTMLDivElement>(null);
  const isHovering = useHover(item);
  const analytics = useAnalytics();
  const linkEl = useMemo(
    () => (href && isExternalURL(href) ? "a" : Link),
    [href],
  );
  const El: any = href ? linkEl : "div";

  return (
    <El
      ref={item as any}
      to={href && !isExternalURL(href) ? href : ""}
      {...(href && isExternalURL(href)
        ? {
            href,
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : {})}
      aria-disabled={disabled}
      aria-selected={active}
      tabIndex={disabled ? -1 : 0}
      data-disabled={disabled}
      className={cx(styles.item, className)}
      style={{ pointerEvents: disabled ?? locked ? "none" : "auto" }}
      onClick={(e: any) => {
        if (disabled) {
          return;
        }

        onClick?.(e as any);
        href &&
          isExternalURL(href) &&
          analytics?.track(AnalyticsEvents.OpenLink, {
            link: href,
          });
      }}
      {...props}
    >
      {isHovering && !noHighlight && (
        <motion.div
          layout="position"
          layoutId={id}
          transition={{ duration: 0.075 }}
          className={styles.highlight}
        />
      )}
      <div
        className={cx(
          styles.content,
          locked && styles.locked,
          active && styles.active,
        )}
      >
        <div className={styles["content-inner"]}>{children}</div>
        {locked && <Lock className={styles.icon} />}
      </div>
    </El>
  );
};
