import { cx } from "$src/lib/utils";
import { useAccount } from "$src/stores/use-account";
import mixins from "$src/styles/mixins.module.css";
import { useVirtualizer } from "@tanstack/react-virtual";
import Fuse from "fuse.js";
import { useMemo, useRef, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import type { BFFOutput } from "@tracksuit/bff/trpc";
import { sortBrands } from "@tracksuit/frontend/utils";

import { Checkbox } from "../checkbox/checkbox";
import {
  ControlledDropdown,
  DropdownItem,
  type DropdownProps,
} from "../dropdown/dropdown";
import { Input } from "../input/input";
import styles from "./account-select.module.css";

export type AccountOrBrand = Partial<
  NonNullable<BFFOutput["metadata"]["list"]>[0]
>;

export type AccountSelectProps = {
  selectBy?: "account" | "brand";
  /** Selected accounts */
  selected: AccountOrBrand[];
  /** Callback when accounts selected */
  onChange(items: AccountOrBrand[]): void;
  /** Whether to enable multi-select */
  multiselect?: boolean;
  /** Whether to disable dropdown */
  disabled?: boolean;
  /** Choose the label name */
  label?: string;
  /** Check if open */
  open: boolean;
  /** Closing */
  openChange(state: boolean): void;
} & Omit<Partial<DropdownProps>, "onChange" | "selected">;

/**
 * @component
 * Account select dropdown with optional search
 */
export const AccountSelect = ({
  selectBy = "account",
  selected,
  onChange,
  multiselect,
  disabled,
  label = "Dashboard",
  open,
  openChange,
  className,
  ...props
}: AccountSelectProps) => {
  const list = useRef(null);
  const accountBrands = useAccount((s) => s.accounts);
  const accounts = useMemo(
    () =>
      [
        ...new Map(accountBrands.map((acc) => [acc.accountId, acc])).values(),
      ].sort(({ accountName: a }, { accountName: b }) => a!.localeCompare(b!)),
    [accountBrands],
  );
  const brands = useMemo(
    () =>
      [
        ...new Map(accountBrands.map((acc) => [acc.brandId, acc])).values(),
      ].sort(sortBrands),
    [accountBrands],
  );
  const items = useMemo(
    () => (selectBy === "account" ? accounts : brands),
    [selectBy, accounts, brands],
  );
  const [fuse, setFuse] = useState<Fuse<AccountOrBrand>>();
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState<AccountOrBrand[]>([]);
  const virtualList = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => list.current,
    estimateSize: () => 48,
    overscan: 10,
  });
  const selectedDisplay = useMemo(() => {
    if (selected.length === 0) {
      return undefined;
    }

    if (selected.length === 1) {
      if (selectBy === "account") {
        return selected[0]?.accountName;
      }
      return selected[0]?.brandName;
    }

    return `${selected.length} selected`;
  }, [selected, selectBy]);

  useDeepCompareEffect(() => {
    setFuse(
      new Fuse((items ?? []) as AccountOrBrand[], {
        keys: [selectBy === "account" ? "accountName" : "brandName"],
        threshold: 0.4,
      }),
    );
  }, [items, selectBy]);

  useDeepCompareEffect(() => {
    if (fuse) {
      setFilteredItems(
        (!search ? items : fuse.search(search).map(({ item }) => item)) ??
          items,
      );
    }
  }, [search, fuse, items]);

  const isActive = (base: AccountOrBrand, item?: AccountOrBrand) => {
    return selectBy === "account"
      ? base.accountId === item?.accountId
      : base.brandId === item?.brandId;
  };

  return (
    <ControlledDropdown
      open={open}
      className={cx(styles.select, className)}
      disabled={disabled}
      theme="select"
      constrained
      label={label}
      selected={selectedDisplay}
      onChange={openChange as any}
      {...props}
      data-testid="account-select-dropdown"
    >
      {items.length > 10 && (
        <div className={styles.search}>
          <Input
            label="Search"
            autoFocus
            onChange={({ target }) => setSearch(target.value)}
          />
        </div>
      )}
      <div ref={list} className={cx(styles.list, mixins.scrollable)}>
        <div
          style={{
            height: `${virtualList.getTotalSize()}px`,
          }}
          className={styles["list-inner"]}
        >
          {virtualList.getVirtualItems().map((virtualItem) => {
            const item = filteredItems[virtualItem.index];
            return (
              <DropdownItem
                className={styles.item}
                style={{
                  position: "absolute",
                  top: 0,
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                id="account-item"
                active={selected.some((acc) => isActive(acc, item))}
                key={selectBy === "account" ? item?.accountId : item?.brandId}
                onClick={() => {
                  if (multiselect) {
                    let newSelected;
                    if (selected.some((acc) => isActive(acc, item))) {
                      newSelected = selected.filter(
                        (acc) => !isActive(acc, item),
                      );
                    } else {
                      newSelected = item ? [...selected, item] : [...selected];
                    }
                    onChange(newSelected);
                  } else {
                    onChange(item ? [item] : []);
                    openChange(false);
                  }
                }}
              >
                {multiselect ? (
                  <Checkbox
                    label={
                      selectBy === "account"
                        ? item?.accountName ?? ""
                        : item?.brandName ?? ""
                    }
                    checked={selected.some((acc) => isActive(acc, item))}
                    onChange={() => undefined}
                    style={{ pointerEvents: "none" }}
                    className={styles.checkbox}
                  />
                ) : (
                  <span className={styles["item-label"]}>
                    {selectBy === "account"
                      ? item?.accountName ?? ""
                      : item?.brandName ?? ""}
                  </span>
                )}
              </DropdownItem>
            );
          })}
        </div>
      </div>
    </ControlledDropdown>
  );
};
