import { apiQueryClient } from "$src/lib/api";

import { useAccount } from "../stores/use-account";

export const useMetadataList = (clientId?: string[] | null) => {
  const { data, isLoading: loading } = apiQueryClient.metadata.list.useQuery(
    {
      clientIds: clientId?.map((id) => Number(id)),
    },
    {
      enabled: typeof clientId !== "undefined",
    },
  );

  return { data, loading };
};

export const useMetadata = (clientId?: string | null, geographyId?: number) => {
  const { data, isLoading: loading } = apiQueryClient.metadata.get.useQuery(
    {
      clientId: Number(clientId),
      geographyId: geographyId!,
    },
    { enabled: geographyId !== undefined && typeof clientId === "string" },
  );

  return { data, loading };
};

export const useMetadataCheck = () => {
  const account = useAccount((s) => s.active);
  const {
    data,
    isLoading: loading,
    isError,
    error,
  } = apiQueryClient.metadata.check.useQuery(
    {
      accountBrandId: account?.accountBrandId as number,
    },
    {
      enabled: !!account,
    },
  );
  if (isError) {
    throw new Error(error.message);
  }

  return { data, loading };
};
