import {
  EMPTY_STATE_CONTENT,
  EmptyState,
} from "$src/components/empty-state/empty-state";
import { Header } from "$src/components/header/header";
import { Toast } from "$src/components/toast/toast";
import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { useShallowUpdateSearchParams } from "$src/hooks/use-shallow-update-search-params";
import { useInitUser } from "$src/hooks/useInitUser/use-init-user";
import { cx } from "$src/lib/utils";
import { useMetadataCheck } from "$src/queries/use-metadata";
import { useAccount } from "$src/stores/use-account";
import {
  useInitFilters,
  useReflectFiltersToUrl,
} from "$src/stores/use-filters";
import mixins from "$src/styles/mixins.module.css";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import styles from "./layout.module.css";

export const AppLayout = () => {
  const { session, ready: authReady } = useAmplifyAuth();
  const navigate = useNavigate();
  const account = useAccount((s) => s.active);
  const initialised = useInitUser();
  const { data } = useMetadataCheck();

  useEffect(() => {
    if (authReady && !session) {
      navigate("/login", {
        replace: true,
      });
    }
  }, [session, authReady]);

  return account ? (
    <main className={cx(styles.app, mixins["grid-page"])}>
      <Header />
      <div className={cx(styles.page, mixins["grid-page"])}>
        <Toast />
        <div className={cx(styles.page, mixins["grid-page"])}>
          {initialised && data?.hasData === true && <AppContent />}
          {data?.hasData === false && (
            <EmptyState
              {...EMPTY_STATE_CONTENT.imagery}
              className={styles["empty-state"]}
            />
          )}
        </div>
      </div>
    </main>
  ) : null;
};

const AppContent = () => {
  const account = useAccount((s) => s.active);

  useInitFilters();
  useReflectFiltersToUrl();
  useShallowUpdateSearchParams("accountBrandId", account?.accountBrandId);

  return <Outlet />;
};
